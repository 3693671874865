/*
 *
 * MonthSimulation
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setLastLogUpdate, setLoader, setMonthLogs } from "store/actions";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import "./styles.scss";
import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { API, Endpoints } from "utils/api";

export class MonthSimulation extends React.Component<any, any> {
	constructor(props: any) {
		console.log("🚀 ~ MonthSimulation ~ constructor ~ props:", props);
		super(props);

		this.state = {
			logs: props?.monthLogs || "",
			lastUpdateDate: props?.lastLogUpdate || ""
		};
	}

	async getData() {
		const { dispatch } = this.props;
		const { logs } = this.state;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriPartnersLevel()
		});

		const currentDatetime = new Date().toISOString();

		let newLogs = logs ? logs + "\n\n" + response.data.results?.logsEntry?.logs : response.data.results?.logsEntry?.logs;

		if (response.ok) {
			this.setState({
				lastUpdateDate: currentDatetime,
				logs: newLogs
			});
		}

		dispatch(setMonthLogs(newLogs));
		dispatch(setLastLogUpdate(currentDatetime));
		dispatch(setLoader(false));
	}

	async revertChanges() {
		const { dispatch } = this.props;
		const { logs } = this.state;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriRevertMonthChanges()
		});

		const currentDatetime = new Date().toISOString();

		const formattedDate = new Date(currentDatetime).toLocaleString();

		let newLogs = logs ? logs + `\nMês Revertido... (${formattedDate}) ` : `Mês Revertido... (${formattedDate}) `;

		if (response.ok) {
			this.setState({
				lastUpdateDate: currentDatetime,
				logs: newLogs
			});
		}
		dispatch(setMonthLogs(newLogs));
		dispatch(setLastLogUpdate(currentDatetime));
		dispatch(setLoader(false));
	}

	cleanConsole() {
		const { dispatch } = this.props;
		const { logs, lastUpdateDate } = this.state;
		this.setState({ logs: "" });
		dispatch(setMonthLogs(""));
		// dispatch(setLastLogUpdate("")); => To delete the last update date from the store
	}

	render() {
		const { logs, lastUpdateDate } = this.state;

		const formattedDate = lastUpdateDate ? new Date(lastUpdateDate).toLocaleString() : "";

		return (
			<React.Fragment>
				<Helmet>
					<meta name="description" content="Description of MonthSimulation" />
				</Helmet>
				<div className="TableComponent">
					<div className="TableMainHeader">
						<span className="MainHeaderTitle">{Strings.monthSimulation.monthClosing}</span>
						<hr className="hr"></hr>
					</div>
					<div>
						<text>{Strings.monthSimulation.monthClosingDescription}</text>
						<div style={{ marginTop: "20px" }}>
							<Button onClick={() => this.getData()} type="primary">
								{Strings.monthSimulation.closeMonth}
							</Button>
							<Button onClick={() => this.revertChanges()} style={{ marginLeft: "10px" }} type="default">
								{Strings.monthSimulation.revertMonth}
							</Button>
						</div>
					</div>
				</div>
				<div style={{ height: "100vh", marginBottom: "50px", marginTop: "10px" }} className="TableComponent">
					<div className="TableMainHeader" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<span className="MainHeaderTitle">{Strings.monthSimulation.promotionsAndDemotionsLogs}</span>
						{formattedDate && (
							<i>
								{Strings.monthSimulation.lastUpdate}: {formattedDate}
							</i>
						)}
					</div>
					<TextArea
						readOnly
						value={logs}
						className="custom-textarea"
						style={{
							display: "flex",
							height: "90%",
							marginTop: "10px"
						}}
					/>
					<div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "10px" }}>
						<Button onClick={() => this.cleanConsole()} disabled={!logs} type="primary">
							{Strings.monthSimulation.cleanUp}
						</Button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	monthLogs: state.monthLogs,
	lastLogUpdate: state.lastLogUpdate
});

export default connect(mapStateToProps)(MonthSimulation);
